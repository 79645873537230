//SPグローバルナビ開閉
$('#gnav-btn').on('click', function() {
  $(this).toggleClass('open');
  $('.gnav-inner').toggleClass('open');

  $('.gnav-inner.open .nav-icon').on('click', function() {
    $(this).toggleClass('acc-open');
    $(this).next('.megamenu').stop(true).slideToggle(300);
  })
});

//ページトップに戻る
$('#pageup').hide();
$(window).on('scroll', function() {
    if ($(this).scrollTop() > 100) {
     $('#pageup').slideDown('fast');
    } else {
        $('#pageup').slideUp('fast');
    }
});

//smoothscroll
function smoothScroll() {
  const scrollSpeed: number = 500,
        buttonOffset: number = 200;

  $('a[href^="#"]').on('click', function() {
    let href: string = $(this).attr('href');
    let target: string = ( href === '#top' && !$('#top').length ) ? 'html' : href,
        position: number = $(target).offset().top;
    $('body, html').animate( {scrollTop:position - 100}, scrollSpeed, 'swing' );
    return false;
  });
  $('#pageup').click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500, 'swing');
    return false;
  });
}
smoothScroll();

//通常アコーディオン
$('.acc').on('click', function() {
  $(this).toggleClass('active');
  $(this).next('.acc-contents').stop(true).slideToggle(300);
});

//タブ切り替え
const tab_menu = '.js-tab .js-tab-menu > *';
const tab_contents = '.js-tab .js-tab-contents > *';
$(tab_menu).on('click', function() {
  let index = $(tab_menu).index(this);
  $(tab_contents).css('display','none');
  $(tab_contents).eq(index).css('display','block');
  $(tab_menu).removeClass('current');
  $(this).addClass('current');
});
